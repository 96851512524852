<template>
  <section v-if="!isCheckout" class="bg-neutral-black-100 py-8 lg:py-11">
    <div class="container">
      <div class="lg:flex">
        <div class="lg:grow">
          <h3 class="font-manrope text-2xl font-bold capitalize">
            {{ footerFormData.title }}
          </h3>
          <p
            class="ui-text-m text-neutral-black-500 mt-1 lg:mt-5 max-lg:tracking-[-0.14px]"
          >
            {{ footerFormData.subTitle }}
          </p>
        </div>
        <div
          class="w-full lg:max-w-[490px] lg:flex-[0_0_490px] pt-6 lg:pt-3 relative"
        >
          <form class="flex items-start" @submit.prevent="onSubmit">
            <UiInput
              id="signUpForSpecialOffers"
              v-model="userEmail"
              class="w-full placeholder:capitalize mr-4"
              name="email"
              :hide-details="false"
              size="md"
              :placeholder="footerFormData.inputPlaceholder"
              data-e2e-placeholder="newsletter-subscription"
            />
            <UiButton
              :loading="loadingEmail"
              type="submit"
              color="primary"
              variant="default"
              size="md"
              class="w-[130px] lg:w-[144px]"
            >
              {{ footerFormData.buttonText }}
              <UiIcon name="arrow_right_short" class="size-6 ml-1.5" />
            </UiButton>
          </form>
          <div
            v-if="emailSend"
            class="absolute left-0 top-[75px] lg:top-[65px] w-full bg-neutral-black-900 text-white px-2 lg:px-3 py-1 ui-text-sm lg:ui-text-m max-w-[265px] lg:max-w-[315px]"
          >
            {{ footerFormData.successMessage }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { object, string } from "yup";
import { useForm } from "vee-validate";
import { FetchError } from "ofetch";
import { useGeneralInternalStore } from "~/stores/generalInternal";

interface InitialForms {
  email: string;
}

const { $api } = useNuxtApp();
const { t } = useI18n();
const route = useRoute();
const { footer } = useGeneralInternalStore();

const userEmail = ref("");
const emailSend = ref(false);
const loadingEmail = ref<boolean>(false);

const initialForm: InitialForms = {
  email: "",
};

const { generateLeadEvent } = useEvents();

const schema = object().shape({
  email: string().email().required().label(t("email")),
});

const formValues = reactive(
  JSON.parse(JSON.stringify(initialForm)),
) as InitialForms;

const { handleSubmit, resetForm, setErrors, errors } = useForm({
  initialValues: formValues,
  validationSchema: schema,
});

const footerFormData = computed(() => {
  return {
    title: footer.footerForm?.title || t("joinOurNewsletter"),
    subTitle:
      footer.footerForm?.subTitle || t("weLoveToSurpriseOurSubscribers"),
    buttonText: footer.footerForm?.buttonText || t("subscribe"),
    successMessage:
      footer.footerForm?.successMessage ||
      t("weVeSentAnEmailToTheProvidedAddress"),
    inputPlaceholder:
      footer.footerForm?.inputPlaceholder || t("yourEmailAddress"),
  };
});

const isCheckout = computed(() => {
  return (
    route.path.includes("checkout") && !route.path.includes("checkout/success")
  );
});

watch(
  () => route.fullPath,
  () => {
    emailSend.value = false;
  },
);

watch(
  errors,
  (val) => {
    if (val.email) {
      emailSend.value = false;
    }
  },
  { deep: true },
);

const onSubmit = handleSubmit(async (values) => {
  loadingEmail.value = true;
  try {
    await $api.general.subscribe(values);
    emailSend.value = true;
    userEmail.value = "";
    generateLeadEvent("subscribe");
    resetForm();
    setTimeout(() => {
      emailSend.value = false;
    }, 5000);
  } catch (error) {
    console.error(error);
    if (error instanceof FetchError) {
      setErrors(responseErrorToObjectErrors(error?.data?.errors));
    }
  } finally {
    loadingEmail.value = false;
  }
});
</script>
