<template>
  <footer class="bg-white">
    <div v-if="!isCheckout" class="py-9 lg:py-12.5">
      <div class="container">
        <div class="w-full lg:flex">
          <div class="lg:w-[162px] lg:order-1 mb-9 lg:mb-0">
            <nuxt-link
              class="mx-auto block w-[162px] max-w-full hover:opacity-80 lg:mx-0"
              :to="localePath('/')"
            >
              <img
                class="object-contain"
                :src="settings?.footer_logo"
                :alt="
                  settings?.company_name
                    ? `${settings?.company_name} ${$t('footerLogo')}`
                    : 'Company logo'
                "
              />
            </nuxt-link>
          </div>
          <div class="lg:grow">
            <div class="lg:flex w-full lg:gap-12 xl:gap-25">
              <CommonFooterLeftColumn v-if="footer.leftColumn" />
              <div
                v-for="(item, i) in mainFooterMenu"
                :key="`parent_link_${i}`"
                class="ui-text-m hide-mobile"
              >
                <template v-if="item.is_title">
                  <div class="font-semibold">
                    {{ item?.currentTranslation?.title }}
                  </div>
                </template>

                <template v-else-if="item.url.includes('btn-id-')">
                  <button
                    :id="extractButtonId(item.url)"
                    class="inline-flex cursor-pointer hover:underline"
                  >
                    {{ item?.currentTranslation?.title }}
                  </button>
                </template>

                <template v-else>
                  <nuxt-link :to="localePath(item.url)" class="font-semibold">
                    {{ item?.currentTranslation?.title }}
                  </nuxt-link>
                </template>
                <ul v-if="item.children?.length" class="mt-5 space-y-2">
                  <li
                    v-for="(link, index) in item.children"
                    :key="`link_${i}_${index}`"
                  >
                    <template v-if="link.is_title">
                      <div class="inline-flex cursor-default">
                        {{ link?.currentTranslation?.title }}
                      </div>
                    </template>

                    <template v-else-if="link.url.includes('btn-id-')">
                      <button
                        :id="extractButtonId(link.url)"
                        class="inline-flex cursor-pointer hover:underline"
                      >
                        {{ link?.currentTranslation?.title }}
                      </button>
                    </template>

                    <template v-else>
                      <nuxt-link
                        :to="localePath(link.url)"
                        class="inline-flex hover:underline"
                      >
                        {{ link?.currentTranslation?.title }}
                      </nuxt-link>
                    </template>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <CommonFooterMobile class="lg:hidden mt-6" />
        <CommonFooterSocial class="mt-9" />
      </div>
    </div>
    <div
      class="lg:border-t lg:border-solid lg:border-neutral-black-200 pb-9 lg:py-4"
    >
      <div class="container">
        <div
          class="ui-text-m text-center [&_p]:ui-text-m"
          v-html="footer.copyright"
        />
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import { useGeneralInternalStore } from "~/stores/generalInternal";

const route = useRoute();
const localePath = useLocalePathPolyfill();
const { footerMenu, settings } = useGeneralStore();
const { footer } = useGeneralInternalStore();

const isCheckout = computed(() => {
  return (
    route.path.includes("checkout") && !route.path.includes("checkout/success")
  );
});

const mainFooterMenu = computed(() => {
  return toTree(footerMenu);
});
</script>
